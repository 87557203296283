import React, { useState } from "react"
import HomeLoanProduct from "models/product-models/HomeLoanProduct"
import Provider from "models/Provider"
import HomeLoanProvider from "models/provider-models/HomeLoanProvider"
import Layout from "components/layout"
import ReturnLinkChain from "components/content/return-link-chain"
import { Typography } from "components/typography"
import * as styles from "./style.module.scss"
import InputFilter from "components/content/home-loan/input-filter"
import ProductComponent from "components/content/home-loan/product-component"
import { ListHeaderProduct } from "components/content/list-header"
import { HomeLoan as ProductDetails } from "components/content/product-details"
import Product from "models/Product.js"
import useMediaQuery from "@mui/material/useMediaQuery"
import { HomeLoanDisclaimer } from "components/content/content-disclaimer"
import AppManager from "services/app-manager"
import * as Clickable from "components/clickable"
import { Link } from "gatsby"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import FactCheckedDialog from "components/content/fact-checked-dialog"

function makeReviewComponent(reviews: string[], p3Array: { [id: string]: any }[]): JSX.Element {
  return (
    <div>
      {reviews.map((review, key) => (
        <Typography.P style={{ marginBottom: "15px" }}>
          {review}{" "}
          {p3Array.map(p3 => (
            <div>
              <Typography.H6>{p3.heading}</Typography.H6>
              {p3.paragraph}
            </div>
          ))}
        </Typography.P>
      ))}
    </div>
  )
}

const ProductTemplate = (props: any) => {
  const { provider, productId, dateUpdated, ads }: any = JSON.parse(props.pageContext.p)
  const isWide = useMediaQuery("(min-width:768px)")
  const product: HomeLoanProduct = HomeLoanProduct.Initialise(provider.products[productId] as HomeLoanProduct)
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  const dateString = Number(dateUpdated.substring(8, 10)) + " " + months[parseInt(dateUpdated.substring(5, 7)) - 1] + " " + dateUpdated.substring(0, 4)

  const appManager = AppManager.getInstance()
  const [loanAmount, setHomeLoanAmount] = useState(appManager.getHomeLoanAmount())
  const [loanDeposit, setLoanDeposit] = useState(appManager.getLoanDeposit())
  const [loanPurpose, setLoanPurpose] = useState(appManager.getLoanPurpose())

  let queryString = ""
  if (typeof window !== "undefined") {
    queryString = window.location.search
  }
  const urlParams = new URLSearchParams(queryString)
  let rateId = props.location.state ? props.location.state.rateId : urlParams.get("rateId")

  let rate = null
  if (rateId == null) {
    let minRate = Number.MAX_VALUE
    Object.keys(product.rates).forEach(rId => {
      const r = product.rates[rId]
      if (r.interestRate < minRate) {
        minRate = r.interestRate
        rateId = rId
        rate = r
      }
    })
  } else {
    rate = product.rates[rateId]
  }

  const filterRate = r => {
    const minAmount = r.minAmount ? (r.minAmount as number) : product.minAmount
    const maxAmount = r.maxAmount ? (r.maxAmount as number) : product.maxAmount
    const maxLvr = r.maxLvr ? (r.maxLvr as number) : product.maxLvr

    if (minAmount <= loanAmount && maxAmount >= loanAmount && maxLvr >= 100 - loanDeposit && ((r.loanPurpose === "OWNER_OCCUPIED" && loanPurpose === "Home") || (r.loanPurpose === "INVESTMENT" && loanPurpose === "Investment"))) {
      return true
    }
    return false
  }

  const ProductInterestRate = () => {
    const piList: JSX.Element[] = []
    const ioList: JSX.Element[] = []

    const sortedRates = Object.keys(product.rates).sort((a, b) => {
      const rateA = product.rates[a]
      const rateB = product.rates[b]

      if (rateA.rateType === "VARIABLE" && rateB.rateType !== "VARIABLE") {
        return -1
      } else if (rateB.rateType === "VARIABLE" && rateA.rateType !== "VARIABLE") {
        return 1
      }

      if (rateA.rateCategory === "OFFER" && rateB.rateCategory !== "OFFER") {
        return -1
      } else if (rateB.rateCategory === "OFFER" && rateA.rateCategory !== "OFFER") {
        return 1
      }

      if (rateA.rateType === "FIXED" && rateB.rateType === "FIXED") {
        const termA = rateA.fixedTerm?.replace(/\D+/g, "")
        const termB = rateB.fixedTerm?.replace(/\D+/g, "")
        if (termA !== termB) {
          return parseInt(termA || "0") - parseInt(termB || "0")
        }
      }

      return rateA.interestRate - rateB.interestRate
    })

    const seenRateTypePI: { [id: string]: string } = {}
    const seenRateTypeIO: { [id: string]: string } = {}

    sortedRates.forEach(rateId => {
      const r = product.rates[rateId]

      if (!filterRate(r)) {
        return
      }

      const rateType = r.rateType === "VARIABLE" ? "Variable".concat(r.rateCategory === "INDEX" ? " Index" : "") : r.fixedTerm?.replace(/\D+/g, "") + " Year Fixed"

      // keep track of rate type added so to skip duplicates
      if (r["repaymentType"] === "PRINCIPAL_AND_INTEREST") {
        if (rateType in seenRateTypePI) {
          return
        }
        seenRateTypePI[rateType] = rateId
      } else if (r["repaymentType"] === "INTEREST_ONLY") {
        if (rateType in seenRateTypeIO) {
          return
        }
        seenRateTypeIO[rateType] = rateId
      }

      const rateRow = (
        <div style={{ display: "flex", borderBottom: "0.5px solid #D2D2D2", paddingTop: "15px", paddingBottom: "15px" }}>
          <div style={{ width: "33%" }}>
            <Typography.P comp="product-detail">{rateType}</Typography.P>
          </div>
          <div style={{ width: "33%", textAlign: "right" }}>
            <Typography.P comp="product-detail-bold">{(r["interestRate"] * 100).toFixed(2)}%</Typography.P>
          </div>
          <div style={{ width: "33%", textAlign: "right" }}>
            <Typography.P comp="product-detail-bold">{(r["comparisonRate"] * 100).toFixed(2)}%</Typography.P>
          </div>
        </div>
      )

      if (r["repaymentType"] === "PRINCIPAL_AND_INTEREST") {
        piList.push(rateRow)
      } else {
        ioList.push(rateRow)
      }
    })

    return (
      <div style={{ marginTop: "40px" }}>
        {piList.length === 0 && ioList.length === 0 && <div style={{ textAlign: "center", fontSize: "24px" }}>No Rates</div>}
        {piList.length > 0 && (
          <div>
            <div className={styles.interestRateHeader}>
              <span>{"Principal & Interest"}</span>
              <span>{"(% p.a.)"}</span>
            </div>
            {isWide ? (
              <div className={styles.rateTableHeader}>
                <div style={{ width: "33%" }}>Rate Type</div>
                <div style={{ width: "33%", textAlign: "right" }}>Interest Rate</div>
                <div style={{ width: "33%", textAlign: "right" }}>Comparison Rate*</div>
              </div>
            ) : (
              <div className={styles.rateTableHeader}>
                <div style={{ width: "33%" }}>
                  Rate
                  <br />
                  Type
                </div>
                <div style={{ width: "33%", textAlign: "right" }}>
                  Interest
                  <br />
                  Rate
                </div>
                <div style={{ width: "33%", textAlign: "right" }}>
                  Comparison
                  <br />
                  Rate*
                </div>
              </div>
            )}
            {piList}
          </div>
        )}
        {ioList.length > 0 && (
          <div>
            <div className={styles.interestRateHeader} style={{ marginTop: "90px" }}>
              <span>{"Interest Only"}</span>
              <span>{"(% p.a.)"}</span>
            </div>
            {isWide ? (
              <div className={styles.rateTableHeader}>
                <div style={{ width: "33%" }}>Rate Type</div>
                <div style={{ width: "33%", textAlign: "right" }}>Interest Rate</div>
                <div style={{ width: "33%", textAlign: "right" }}>Comparison Rate*</div>
              </div>
            ) : (
              <div className={styles.rateTableHeader}>
                <div style={{ width: "33%" }}>
                  Rate
                  <br />
                  Type
                </div>
                <div style={{ width: "33%", textAlign: "right" }}>
                  Interest
                  <br />
                  Rate
                </div>
                <div style={{ width: "33%", textAlign: "right" }}>
                  Comparison
                  <br />
                  Rate*
                </div>
              </div>
            )}
            {ioList}
          </div>
        )}
      </div>
    )
  }

  const expertReviewSection = () => {
    return (
      <div>
        <ListHeaderProduct>Expert Review</ListHeaderProduct>
        {product.getExpertReview(provider, makeReviewComponent)}
      </div>
    )
  }

  let breadcrumbName = product.name.replace(provider.name, "") || product.name

  return (
    // <Layout title={`${nameHeading} - Review | Stay or Go`} description={product.autoDescription} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated}>
    <Layout title={product.nameLong + ` - Review | Stay or Go`} description={product.autoDescription} urlSlug={product.urlSlug ? product.urlSlug : undefined} dateUpdated={dateUpdated}>
      <div className="page-container">
        <div className="content-provider-container">
          <ReturnLinkChain
            linkChain={[
              { name: "Home Loans", to: "/home-loans/" },
              { name: provider.name, to: "/" + provider.urlSlug + "-home-loans" },
            ]}
            currentLocationItem={{ name: breadcrumbName }}
            excludeBottomMargin={isWide}
            includeFactCheckedDialog={isWide}
            factCheckedDialogDateUpdated={dateString}
          />
          <div className={styles.heading}>
            {/* {makeSchemaOrg(product, provider, nameHeading)} */}
            <Typography.H1>{product.nameLong}</Typography.H1>
          </div>
          {product.forSale === "GRANDFATHERED" ? <div className={styles.subHeading}>(no longer for sale)</div> : null}
          {product.forSale === "PAUSED" ? <div className={styles.subHeading}>(currently not for sale)</div> : null}
          <div className={styles.description}>
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              {product.autoDescription}
            </Typography.P>
            <br />
            <Typography.P comp={"content-p"} fontColor={"#A6A6A6"}>
              Updated {dateString}
            </Typography.P>
            {!isWide && (
              <>
                <br />
                <FactCheckedDialog dateString={dateString} />
              </>
            )}
          </div>
          <div className="show-for-desktop">
            <ProductComponent product={product} rateId={rateId} loanAmount={loanAmount} loanDeposit={loanDeposit} hideName />
          </div>
          <div className="show-for-mobile">
            <ProductComponent product={product} rateId={rateId} loanAmount={loanAmount} loanDeposit={loanDeposit} hideName mobileProduct />
          </div>
          <div className={styles.getStarted}>
            <div style={{ width: "auto" }}>
              <div style={{ fontSize: "18px", fontWeight: "bold", fontFamily: "Arboria-Bold, sans-serif", color: "#E7527A", marginBottom: "11px" }}>Already have a home loan?</div>
              <Typography.P comp={"content-p"}>See what your bank will offer you to stay</Typography.P>
            </div>
            <div className={styles.getStartedButton}>
              <Clickable.Text className={styles.button} secondary component={Link} to={"/home-loans/instructions/"} onClick={DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOME-LOANS" })}>
                Get Started
              </Clickable.Text>
            </div>
          </div>
          <Typography.P className={styles.seeDetailsBelow} comp={"flat-18"}>
            * See details below
          </Typography.P>
          <ListHeaderProduct>Interest Rates</ListHeaderProduct>
          <InputFilter setHomeLoanAmount={setHomeLoanAmount} setLoanDeposit={setLoanDeposit} setLoanPurpose={setLoanPurpose} />
          <ProductInterestRate />
          {expertReviewSection()}
          <ListHeaderProduct>Product Details</ListHeaderProduct>
          {product.description !== null ? (
            <div style={{ marginBottom: "30px", marginTop: "30px" }}>
              <div style={{ color: "#797979", fontFamily: "Arboria-Book, sans-serif", fontSize: "16px", display: "flex" }}>
                <img alt="" src="/images/icon_quote.png" style={{ width: "20px", height: "15.66px", marginRight: "15px" }} />
                <div>{product.description}"</div>
              </div>
              <div style={{ color: "#E7527A", fontFamily: "Arboria-Bold, sans-serif", textAlign: "right", fontSize: "16px" }}>- {provider.name}</div>
            </div>
          ) : null}
          <ProductDetails product={product} provider={provider} />
          {/* {provider.getAlternativeProductList(product, makeProductComponent).length > 0 ? (
            <div>
              <div className={styles.listHeader} style={{ marginTop: "0px" }}>
                <div style={{ marginLeft: "20px", color: "white", fontFamily: "Arboria-Bold, sans-serif", fontWeight: 700, lineHeight: "40px", fontSize: "22px" }}>Alternative Credit Cards</div>
              </div>
              <ListHeaderProduct style={{ marginTop: "0px" }}>Alternative Credit Cards</ListHeaderProduct>
            </div>
          ) : null}
          {provider.getAlternativeProductList(product, makeProductComponent)} */}
          <HomeLoanDisclaimer isDisclaimerOpen />
        </div>
      </div>
    </Layout>
  )
}

export default ProductTemplate
