// extracted by mini-css-extract-plugin
export var boldText = "style-module--bold-text--90765";
export var button = "style-module--button--1872b";
export var cardImage = "style-module--card-image--4e20e";
export var cardImageImg = "style-module--card-image-img--d9c74";
export var cardRow = "style-module--card-row--449d3";
export var cardValuesHeader = "style-module--card-values-header--c036c";
export var description = "style-module--description--0774c";
export var getStarted = "style-module--get-started--e9973";
export var getStartedButton = "style-module--get-started-button--9ca11";
export var heading = "style-module--heading--379ba";
export var interestRateHeader = "style-module--interest-rate-header--dc48f";
export var listHeader = "style-module--list-header--cfa61";
export var rateTableHeader = "style-module--rate-table-header--263d0";
export var seeDetailsBelow = "style-module--see-details-below--3128a";
export var subHeading = "style-module--sub-heading--d8d9d";